import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { HeaderAccountMenuComponent } from './header-account-menu.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
	declarations: [HeaderAccountMenuComponent],
	imports: [SharedModule, MatMenuModule],
	exports: [HeaderAccountMenuComponent],
})
export class HeaderAccountMenuModule {}
