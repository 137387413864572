<div class="container-fluid p-3 p-lg-4">
	<span class="d-block fs-16px fw-500 mb-1 lh-22px">{{ data.title }}</span>

	<span class="d-block fs-16px fw-400 lh-22px fg-general-4">{{ data.message }}</span>

	<div class="row mx-0 align-items-center mt-4">
		<div class="col px-0">
			<button mat-dialog-close mat-flat-button color="primary" class="w-100 px-0" (click)="dailogRef.close(false)">
				<span class="fs-16px">{{ data.cancelButton }}</span>
			</button>
		</div>

		<div class="col-auto px-0">
			<button mat-flat-button (click)="dailogRef.close(true)">
				<span class="fs-16px fg-prim-2">{{ data.confirmButton }}</span>
			</button>
		</div>
	</div>
</div>
