import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'findqo-logout-dialog',
	templateUrl: './logout-dialog.component.html',
	styleUrls: ['./logout-dialog.component.scss'],
})
export class LogoutDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dailogRef: MatDialogRef<LogoutDialogComponent>) {}
}
